import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import globalconfig from '../../../../common/config';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';


const ACTION_STEP_STATUSES = {
    QUEUED: "QUEUED",           // ☐
    IN_PROGRESS: "IN_PROGRESS", // ◌
    COMPLETED: "COMPLETED",     // ☑
    ERROR: "ERROR",             // !
    TIMEOUT: "TIMEOUT"          // ◌ + error message
}

const ACTION_STEP_STATUS_ICON_IDS = { // https://fonts.google.com/icons
    QUEUED: "check_box_outline_blank",
    //IN_PROGRESS: "custom_activity_indicator",
    COMPLETED: "check_box",
    ERROR: "error",
    //TIMEOUT: "custom_activity_indicator",
}

const ACTION_STEP_IDS = {
    REQUEST_LOGS: "REQUEST_LOGS",
    POLLING_FOR_RECENT_LOGS: "POLLING_FOR_RECENT_LOGS",
    REQUEST_ACTION: "REQUEST_ACTION",
    POLLING_FOR_RECENT_ACTION: "POLLING_FOR_RECENT_ACTION"
}

//Steps specific to certain actions, requesting logs is always first
const REBOOT_STEPS = {
    REQUEST_ACTION: { // ACTION_STEP_IDS.REQUEST_ACTION
        description: "Proceed with reboot",
        supplementaryDetail: null,
        status: ACTION_STEP_STATUSES.QUEUED,
        // Date.getTime() millisecond timestamp, tested against EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_REBOOT
        requestDatetime: null
    },
    POLLING_FOR_RECENT_ACTION: { // ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION
        description: "Raven reboot successful",
        supplementaryDetail: "(Checking every 30 seconds)",
        status: ACTION_STEP_STATUSES.QUEUED
    }
}

const FACTORYRESET_STEPS = {
    REQUEST_ACTION: { // ACTION_STEP_IDS.REQUEST_ACTION
        description: "Proceed with factory reset",
        supplementaryDetail: null,
        status: ACTION_STEP_STATUSES.QUEUED,
        // Date.getTime() millisecond timestamp, tested against EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_REBOOT
        requestDatetime: null
    },
    POLLING_FOR_RECENT_ACTION: { // ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION
        description: "Raven factory reset successful",
        supplementaryDetail: "(Checking every 30 seconds)",
        status: ACTION_STEP_STATUSES.QUEUED
    }
}

const TOMBSTONE_MAX_AGE_SECONDS = 18000; // How many seconds from now before tombstones are considered stale (5 hours)
const TOMBSTONE_POLLING_INTERVAL_MILLISECONDS = 30000; // 30 seconds
const TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS = 300000; // 5 minutes
const EVENT_RAVEN_REBOOT_MAX_AGE_SECONDS = 900; // How many seconds from now before a raven reboot is not associated with the reboot action request (15 mins)
const EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS = 900000; // 15 minutes

export default class ActionsRebootRavenOverlay extends React.PureComponent {

    static propTypes = {
        dataStore: PropTypes.object.isRequired, // for requesting refresh for missing details on app header sort displayVariable changes
        raven: PropTypes.object.isRequired,
        feature: PropTypes.object.isRequired,
        oldestLoadedEventMoment: PropTypes.object, // moment.js
        mostRecentLoadedRavenBootedEventMoment: PropTypes.object, // moment.js
        stage: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
        onActionClose: PropTypes.func.isRequired,
        persistStateInLocalStorage: PropTypes.func.isRequired,
        restoredActionsOverlayState: PropTypes.object,
        selectedAction: PropTypes.object.isRequired
    };

    //For resetting the state when the component unmounts
    defaultActionSteps = {
        REQUEST_LOGS: { // ACTION_STEP_IDS.REQUEST_LOGS
            description: "Requesting logs",
            supplementaryDetail: null,
            status: ACTION_STEP_STATUSES.QUEUED,
            requestDatetime: null // Date.getTime() millisecond timestamp, tested against TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_LOGS
        },
        POLLING_FOR_RECENT_LOGS: { // ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS
            description: "Logs uploaded",
            supplementaryDetail: "(Checking every 30 seconds)",
            status: ACTION_STEP_STATUSES.QUEUED
        }
    }

    initialState = { // used for resetting state
        cancelButtonIsDisabled: false,
        actionStartTime: new Date().getTime(),
        actionCompleted: false, // corresponds to POLLING_FOR_RECENT_REBOOT.status === COMPLETED
        actionErrorOccurred: false,
        actionStatusMessage: null, // displayed red if actionErrorOccurred
        actionSteps: { // Requesting logs is always first, additional steps added in constructor based on props
            ...this.defaultActionSteps,
        },
        overridableTimeoutOccurred: false
    };

    constructor(props) {
        super(props);

        // If it's not a log request, adjust steps based on the action requested
        if (props.selectedAction.value !== "logrequest") {
            if (props.selectedAction.value === "reboot") {
                this.initialState.actionSteps = {
                    ...this.initialState.actionSteps,
                    ...REBOOT_STEPS
                };
            } else if (props.selectedAction.value === "factoryreset") {
                this.initialState.actionSteps = {
                    ...this.initialState.actionSteps,
                    ...FACTORYRESET_STEPS
                };
            }
        }

        this.state = this.initialState;

        this.queryLogDataInterval = undefined;
        this.queryRavenEventsInterval = undefined;
    }

    componentDidMount() {
        if (this.props.raven && this.props.raven.Id) {
            if (this.props.restoredActionsOverlayState) {
                this.setRestoredActionsOverlayState(); // this will attempt to check request timeouts and resume intervals for the corresponding state
            } else {
                // start action from the beginning:
                this.requestActionAndStartPolling("LOGREQUEST");
            }
        }
    }

    componentWillUnmount() {
        if (!this.state.actionCompleted) //Flag to stop storing state if action is completed
            this.persistStateInLocalStorage();
        clearInterval(this.queryLogDataInterval);
        clearInterval(this.queryRavenEventsInterval);
        this.initialState.actionSteps = { ...this.defaultActionSteps }; // reset the steps to default
    }

    componentDidUpdate(prevProps, prevState) {//(prevProps, prevState, snapshot) {
        if (this.state.actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION] &&
            this.state.actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
            this.checkMostRecentLoadedRavenBootedEventMoment();
        }
    }

    /**
     * Removes the state from local storage when the overlay is closed.
     */
    onClose = () => { this.props.onActionClose(); }

    /**
     * Persists the state of the actions overlay state in local storage.
     */
    persistStateInLocalStorage = () => { this.props.persistStateInLocalStorage(this.state); }

    /**
     * Restores the state of the actions overlay based on the state stored in localstorage.
     */
    setRestoredActionsOverlayState = () => {

        if (!this.props.restoredActionsOverlayState) return; //This is redundant

        this.setState(this.props.restoredActionsOverlayState, () => {

            // Some cleanup for particular states to help resume gracefully, based on when requests were sent
            if (this.state.actionCompleted) return;// do nothing; (state does not need to be resumed)

            const now = new Date();
            const { actionSteps } = this.state;

            const actionStartTime = this.state.actionStartTime;
            if (now.getTime() - actionStartTime > EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS) {

                const revertedState = this.initialState;
                revertedState.actionErrorOccurred = true;
                revertedState.actionStatusMessage = "A previous request stalled waiting for logs. Click 'Cancel' and retry, or click below to try rebooting raven now.";
                revertedState.overridableTimeoutOccurred = true;
                this.setState({ ...revertedState }, this.persistStateInLocalStorage);

                return;
            }


            // RESUME ACTIVITES BASED ON RESTORED STATE
            /** TODO: Check for error states?
             * Possible states to resume from:
             * - REQUEST_LOGS: QUEUED - Logs were not requested yet
             * - REQUEST_LOGS: IN_PROGRESS - Logs were requested, but the callback to check for logs was not called
             * - POLLING_FOR_RECENT_LOGS: QUEUED - Logs were requested, but we did not start polling for logs yet
             * - POLLING_FOR_RECENT_LOGS: IN_PROGRESS - Logs were requested, and we started polling for logs but did not complete
             * - POLLING_FOR_RECENT_LOGS: COMPLETED - Logs were requested, and we started polling for logs and completed (Check if this was all that was needed)
             * - REQUEST_ACTION: QUEUED - Action was not requested yet
             * - REQUEST_ACTION: IN_PROGRESS - Action was requested, but the callback to check for the action was not called
             * - POLLING_FOR_RECENT_ACTION: QUEUED - Action was requested, but we did not start polling for the action yet
             * - POLLING_FOR_RECENT_ACTION: IN_PROGRESS - Action was requested, and we started polling for the action but did not complete
             * - POLLING_FOR_RECENT_ACTION: COMPLETED - Action was requested, and we started polling for the action and completed ( we are done )
             */

            if (actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.QUEUED) {
                this.requestActionAndStartPolling("LOGREQUEST");
                return;
            } else if (actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.IN_PROGRESS || //check if the request was sent
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.QUEUED ||
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                this.queryLogData();
                this.queryLogDataInterval = setInterval(this.queryLogData, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
                return;
            } else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.COMPLETED) {
                if (this.props.selectedAction.id !== "logrequest") {
                    this.requestActionAndStartPolling(this.props.selectedAction.id);
                } else {
                    // We are done
                    return;
                }
            } else if (actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].status === ACTION_STEP_STATUSES.QUEUED) {
                this.requestActionAndStartPolling(this.props.selectedAction.id);
                return;
            } else if (actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS ||
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.QUEUED ||
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                this.refreshRavenEvents();
                return;
            } else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.COMPLETED) {
                // We are done
                return;
            }
        });

    }

    /**
     * Makes an http call to AWS using the provided URL and executes the onSuccess callback on success, and onError on error.
     * @param {str} url - URL to fetch from AWS, requires all necessary parameters for given request. We don't check for missing parameters here. 
     * @param {func} onSuccess - Callback function to execute on successful fetch
     * @param {func} onError - Callback function to execute on error
     * @returns 
     */
    fetchAWS = (url, onSuccess, onError) => {
        return fetch(url, {
            method: 'POST',
            headers: { Authorization: CognitoUtil.getCurrentUserToken() }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then((data) => {
                //length is a bit of hack here, need a better way to check for success between different endpoints
                if (data.status === "SUCCESS" || data.length > 0) {
                    onSuccess(data);
                } else if (data.message) {
                    onSuccess(data, data.message); //Doesn't make sense but keeping for legacy
                }
            })
            .catch((error) => {
                onError(error);
            });
    }

    /**
     * Sends a request to AWS to perform an action on a Raven unit and starts polling for the action to complete.
     * 
     * @param {*} actionToTake Action to send to AWS, taken from props.selectedAction.id
     *  - LOGREQUEST: Request logs from Raven
     *  - REBOOT: Request Raven to reboot
     *  - FACTORYRESET: Request Raven to factory reset
     */
    requestActionAndStartPolling = (actionToTake) => {
        let actionSteps = this.state.actionSteps;
        let currentStep = (actionToTake === "LOGREQUEST") ? ACTION_STEP_IDS.REQUEST_LOGS : ACTION_STEP_IDS.REQUEST_ACTION;

        /**
         * Updates the action steps state and triggers polling based on the action to take.
         *      Currently only logs, reboot, and factoryreset actions are supported.
         * @param {any} _ - Placeholder parameter, not used in the function.
         * @param {string|null} errorMessage - Optional error message.
         */
        const updateActionStepsThenPoll = (_, errorMessage = null) => {
            actionSteps = this.state.actionSteps;

            // update log polling step's status icon and supplementary detail
            if (errorMessage) {
                actionSteps[currentStep].status = ACTION_STEP_STATUSES.ERROR;
            } else {
                actionSteps[currentStep].status = ACTION_STEP_STATUSES.COMPLETED;
            }
            actionSteps[currentStep].requestDatetime = new Date().getTime();

            if (errorMessage) {
                this.setState({
                    actionErrorOccurred: true,
                    actionStatusMessage: errorMessage,
                    overridableTimeoutOccurred: true // present a proceed anyway prompt to still allow trying to reboot raven
                }, this.persistStateInLocalStorage);
            } else {
                this.setState({
                    actionSteps: { ...actionSteps }
                }, this.persistStateInLocalStorage);
            }

            if (actionToTake === "LOGREQUEST") {
                // proceed with log polling regardless of error (timeout for new logs will provide an option to proceed anyway)
                clearInterval(this.queryLogDataInterval); // probably not necessary, but doing it anyway for due diligence

                actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.COMPLETED;
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.IN_PROGRESS;
                this.setState({
                    actionSteps: { ...actionSteps },
                    actionStatusMessage: "Requesting logs from raven."
                }, this.persistStateInLocalStorage);
                // start polling for logs. seems a bit wasteful to repeatedly pull all logs, something to look into later
                this.queryLogDataInterval = setInterval(this.queryLogData, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);

            } else if (!this.state.actionErrorOccurred) { //Don't start polling if an error occurred
                // proceed with raven action polling
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.IN_PROGRESS;
                this.setState({
                    actionSteps: { ...actionSteps }
                }, this.persistStateInLocalStorage);
                clearInterval(this.queryRavenEventsInterval); // probably not necessary, but doing it anyway for due diligence
                this.queryRavenEventsInterval = setInterval(this.refreshRavenEvents, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
            }

        };

        actionSteps[currentStep].status = ACTION_STEP_STATUSES.IN_PROGRESS;

        this.setState({
            actionSteps: { ...actionSteps },
            actionStatusMessage: "Sending a " + actionToTake + " request to Raven."
        }, this.persistStateInLocalStorage);

        const url = this.props.dataStore.prefix2 + "performravenaction"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&action=" + actionToTake
            + "&reason=" + encodeURIComponent(this.props.reason);

        this.fetchAWS(url, updateActionStepsThenPoll, updateActionStepsThenPoll);
    }

    /**
     * Requests the latest logs from the Raven, and checks if the logs are recent enough 
     * TODO: match the logs to the specific action event, not just any recent logs
     * @returns {void}
     */
    queryLogData = () => {
        const { raven } = this.props;
        let actionSteps = this.state.actionSteps;

        if (!raven) {
            this.props.onActionClose();
            return;
        }

        const ravenId = raven.Id;
        const ravenUuid = raven.Raven["Raven UUID"];
        const ravenOSVersion = raven.Build['OS Version'];

        // perform ajax calls to get it the next state
        const url = process.env.REACT_APP_KLOUD_API_BASE_URL + "query/logs"
            + "?stage=" + this.props.stage
            + "&type=tombstones"
            + "&raven=" + ravenId
            + "&uuid=" + ravenUuid
            + "&version=" + ravenOSVersion;

        this.fetchAWS(url,
            this.checkLogData.bind(this),
            (error) => { // onError callback
                actionSteps = this.state.actionSteps;
                actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.ERROR;
                this.setState({
                    actionSteps: { ...actionSteps },
                    actionErrorOccurred: true,
                    actionStatusMessage: error.message,
                    overridableTimeoutOccurred: true
                }, this.persistStateInLocalStorage);
            }
        );
    }

    /**
     * Scan the logs from the Raven to check for recent tombstone logs.
     * @param {*} tombstones List of the logs from the Raven
     * @returns {void}
     */
    checkLogData(tombstones, errorMessage = null) {

        if (errorMessage) {
            console.error("Error querying logs: " + errorMessage);
            this.setState({
                actionErrorOccurred: true,
                actionStatusMessage: errorMessage,
                overridableTimeoutOccurred: true
            }, this.persistStateInLocalStorage);
            return;
        }

        if (!tombstones || !tombstones.length) return; // no logs yet

        this.setState({
            actionStatusMessage: "Checking for recent logs from raven."
        }, this.persistStateInLocalStorage);

        const updateActionStepsForLogStatus = (pollingForRecentLogsStatus, supplementaryDetail = null) => {

            const { actionSteps } = this.state;

            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = pollingForRecentLogsStatus;
            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].supplementaryDetail = supplementaryDetail;

            if (pollingForRecentLogsStatus === ACTION_STEP_STATUSES.IN_PROGRESS) { // if no recent logs are found:

                if (actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.QUEUED) { // if logs not requested yet:
                    this.requestActionAndStartPolling("LOGREQUEST");

                } else {

                    const logRequestDatetime = this.state.actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].requestDatetime;

                    if (logRequestDatetime) {

                        const now = new Date();

                        if (now.getTime() - logRequestDatetime > TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS) {
                            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.TIMEOUT;
                            this.setState({
                                actionSteps: { ...actionSteps },
                                actionErrorOccurred: true,
                                actionStatusMessage: "New logs appear to be delayed.  It may take several minutes.",
                                overridableTimeoutOccurred: true
                            }, this.persistStateInLocalStorage);
                            return;
                        }
                    }
                }
            } else if (pollingForRecentLogsStatus === ACTION_STEP_STATUSES.COMPLETED) { // if recent logs found:
                // set previous step to request logs as completed (useful if returning to the overlay while global in-progress flag still set)
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.COMPLETED;
                clearInterval(this.queryLogDataInterval);

                if (this.props.selectedAction.value === "logrequest") {
                    this.setState({
                        actionCompleted: true,
                        actionSteps: { ...actionSteps },
                        actionStatusMessage: "Logs uploaded successfully. Log timestamp: " + supplementaryDetail
                    }, this.persistStateInLocalStorage);
                    return;
                } else {
                    this.requestActionAndStartPolling(this.props.selectedAction.id);
                }
            }

            /* I don't know why this is here
            this.setState({
                actionSteps: { ...actionSteps }
            }, this.persistStateInLocalStorage); */
        };

        let mostRecentLogsTimestampDescription;

        for (let i = 0; i < tombstones.length; i += 1) {

            if (!tombstones[i].name) { continue; }

            if (tombstones[i].name.includes("logd")) {

                if (!tombstones[i].LastModified) { continue; }

                const tombstoneLastModified = moment.utc(tombstones[i].LastModified);

                if (tombstoneLastModified.isAfter(this.state.actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].requestDatetime)) {

                    mostRecentLogsTimestampDescription = tombstoneLastModified.local().format(globalconfig.display.timeFormat) + " (" + tombstoneLastModified.fromNow() + ")";
                    updateActionStepsForLogStatus(ACTION_STEP_STATUSES.COMPLETED, mostRecentLogsTimestampDescription);
                    return;

                } else {

                    mostRecentLogsTimestampDescription = tombstoneLastModified.local().format(globalconfig.display.timeFormat) + " (" + tombstoneLastModified.fromNow() + ")";
                }
            }
        }

        if (!mostRecentLogsTimestampDescription) {
            this.setState({
                actionStatusMessage: "No recent logs. Please wait for raven to upload logs.  This may take several minutes."
            }, this.persistStateInLocalStorage);
        } else {
            this.setState({
                actionStatusMessage: "Most recent logs are " + mostRecentLogsTimestampDescription + ". Waiting for newer logs."
            }, this.persistStateInLocalStorage);
        }

        updateActionStepsForLogStatus(ACTION_STEP_STATUSES.IN_PROGRESS); // redundant status update (already in progress), used to check if log request necessary and check for timeout
    }

    /**
     * Checks the most recent events for a recent reboot event and updates the action steps accordingly.
     * @returns {void}
     */
    checkMostRecentLoadedRavenBootedEventMoment() {

        const { actionSteps } = this.state;
        let mostRecentRebootTimestampDescription;
        const mostRecentTimeRavenBootedEvent = this.props.mostRecentLoadedRavenBootedEventMoment ? this.props.mostRecentLoadedRavenBootedEventMoment : null;

        if (!this.props.mostRecentLoadedRavenBootedEventMoment) {
            // There are no recent reboot events yet:

            if (this.state.actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                this.setState({
                    actionStatusMessage: "Waiting for raven to report a new reboot event."
                }, this.persistStateInLocalStorage);
            }
            // no return (will check for timeout below)

        } else if (mostRecentTimeRavenBootedEvent.isAfter(actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].requestDatetime)) {
            // Recent reboot event detected and it is recent enough:

            clearInterval(this.queryRavenEventsInterval);

            mostRecentRebootTimestampDescription = this.props.mostRecentLoadedRavenBootedEventMoment.local().format(globalconfig.display.timeFormat) + " (" + this.props.mostRecentLoadedRavenBootedEventMoment.fromNow() + ")";

            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.COMPLETED;
            //actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_REBOOT].supplementaryDetail = ;
            this.setState({
                actionCompleted: true,
                actionSteps: { ...actionSteps },
                actionStatusMessage: "Raven " + this.props.selectedAction.label + " successful: " + mostRecentRebootTimestampDescription
            }, this.persistStateInLocalStorage);
            return; // reboot was successful

        } else {
            // Recent reboot event detected but it is not recent enough:

            mostRecentRebootTimestampDescription = this.props.mostRecentLoadedRavenBootedEventMoment.local().format(globalconfig.display.timeFormat) + " (" + this.props.mostRecentLoadedRavenBootedEventMoment.fromNow() + ")";

            this.setState({
                actionStatusMessage: "Most recent reboot is " + mostRecentRebootTimestampDescription + ". Waiting for raven to report a newer reboot event."
            }, this.persistStateInLocalStorage);
            // no return (will check for timeout below)
        }

        // at this point, reboot event is still pending (either no event yet, or event is not recent enough):
        const rebootRequestDatetime = this.state.actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].requestDatetime;

        if (rebootRequestDatetime) {

            const now = new Date();

            if (now.getTime() - rebootRequestDatetime > EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS) {

                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.TIMEOUT;

                this.setState({
                    actionSteps: { ...actionSteps },
                    actionErrorOccurred: true,
                    actionStatusMessage: "Raven reboot appears to be delayed. The raven may not be connected. Click below to retry.",
                    overridableTimeoutOccurred: true // present a proceed anyway prompt to still allow trying to reboot raven
                }, this.persistStateInLocalStorage);
            }
        }
    }

    /**
     * Requests events from the Raven to check for a recent reboot event.
     */
    refreshRavenEvents = () => {

        if (this.props.feature) {
            // for Diagnostics component 'Last reboot time' and also related to Actions event polling
            this.props.dataStore.getRavenEvents(this.props.feature);
        }

        let actionSteps = this.state.actionSteps;
        actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.IN_PROGRESS;
        this.setState({
            actionSteps: { ...actionSteps },
            actionStatusMessage: "Refreshing recent events from raven."
        }, this.persistStateInLocalStorage);

    }

    iconElementForStatus = (status) => {

        let statusIconElement;
        if (status === ACTION_STEP_STATUSES.IN_PROGRESS || status === ACTION_STEP_STATUSES.TIMEOUT) {
            statusIconElement = <span className="action-step-status-icon"><ActivityIndicator /></span>;
        } else {
            statusIconElement = <span className="material-icons-outlined action-step-status-icon">{ACTION_STEP_STATUS_ICON_IDS[status]}</span>;
        }
        return statusIconElement;
    }

    render() {

        let actionMessageClassName = "action-message";
        if (this.state.actionErrorOccurred) {
            actionMessageClassName += " error";
        }

        let proceedAnywayButton = null;
        if (this.state.overridableTimeoutOccurred) {
            proceedAnywayButton = <button className="message-button" onClick={this.requestActionAndStartPolling}>Proceed Anyway</button>
        }

        return (
            <div className="raven-actions-pending-overlay">
                { this.state.actionCompleted ?
                    <header>
                        Action completed
                        <button className="success-button" disabled={this.state.cancelButtonIsDisabled} onClick={this.onClose}>Dismiss</button>
                    </header>
                    :
                    <header>
                        Action in progress
                        <button className="close-button" disabled={this.state.cancelButtonIsDisabled} onClick={this.onClose}>Cancel</button>
                    </header>
                }

                {/** Loop through the action steps and display them */}
                {
                    Object.keys(this.state.actionSteps).map((stepId) => (
                        <div className="action-step" key={stepId}>
                            {this.iconElementForStatus(this.state.actionSteps[stepId].status)}
                            {this.state.actionSteps[stepId].description}
                        </div>
                    ))
                }

                <div className={actionMessageClassName}>
                    <div>{this.state.actionStatusMessage}</div>
                    {proceedAnywayButton}
                </div>
            </div>
        );
    }
}

class ActivityIndicator extends React.PureComponent {
    render() {
        return <div className="activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    }
}
