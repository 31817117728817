import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates';

import Dialog from './dialog';

import CognitoUtil from '../../../aws/cognito/cognitoUtil';
import useScanDetection from 'use-scan-detection';


/*
    Class to poll the OTA status as it is progressing
*/
export class RemoveOrderModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            orderno: null,
            comment: null,
        };
    }

    onOrderChange = (val) => {
        this.setState({orderno: val});
    }

    onCommentChange = (val) => {
        this.setState({comment: val});
    }

    onRemoveOrder = () => {
        console.log("Removing ", this.state.orderno);
        this.props.onSuccess(this.state.orderno.value, this.state.comment);

        this.props.onClose();
    }

    render() {

        var title = "Cancel Order";

        if(this.props.data && this.props.data.account && this.props.data.account.email)
        {
            title += " for ";
            title += this.props.data.account.email;
        }

        var orders = this.props.data.orders.filter((elem) => {
                return !elem.canceled;
            }).map((elem) => {
                return { value: elem.orderNumber, label: elem.orderNumber + ": " + elem.createDate + " (" + elem.quantity + " Ravens)" };
        });

        return (
            <Dialog
                size="small"
                label={title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >

                <div className="row">
                    <div className="col-3">
                        Order Number:
                    </div>
                    <div className="col-9">
                        <Select id="orderno"
                        placeholder="Order..."
                        searchable={true}
                        value={this.state.orderno}
                        options={orders}
                        name="orderno"
                        onChange={this.onOrderChange}
                        clearable={false} />
                    </div>
                    <div className="col-3">
                        *Comment:<br />
                        <small>(*Required)</small>
                    </div>
                    <div className="col-9">
                        <input name="comment" 
                            type="text" 
                            onChange={(e) => { this.onCommentChange(e.target.value) }}
                            value={this.state.comment}
                        />
                    </div>
                    <div className="col-12">
                        <div align="right">
                            <button onClick={this.props.onClose}>Do Nothing</button> 
                            { this.state.comment ? 
                                <button onClick={this.onRemoveOrder}>Cancel Order</button> :
                                <button disabled onClick={this.onRemoveOrder}>Cancel Order</button>
                            }
                        </div>
                    </div>

                </div>


            </Dialog>
        );
    }

}


const RavenSerialNumberInput = (props) => {

    useScanDetection({
        onComplete: props.setOnComplete,
        minLength: 12 // raven1 sn length 12, raven2 sn length 13
    });

    return (
        <input
            name={`ravenserialnumberinput-${props.idx}`}
            value={props.ravenSerialNumbers[props.idx]}
            type={props.type}
            onChange={props.setOnChange}
            onComplete={props.setOnComplete}
            onFocus={props.setOnFocus}
        />
    );
};

/* Class to ask the user to perform OTA and to display the result from das Kloud
*/
export class AddOrderModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            orderno: null,
            count: 0,
            countMax: 500, // prevents crashes rendering too many fields
            comment: null,
            shippedDate: moment(),
            ravenSerialNumbers: [],
            modalNotificationIsOpen: false,
            modalNotificationText: null,
            datePickerFocused: false,
            currentlySelectedField: 0
        };
    }

    componentDidMount() {

        if(this.props.currentOrder)
        {
            // Populate the state with the current order
            this.setState({
                orderno: this.props.currentOrder.orderNumber,
                count: this.props.currentOrder.quantity,
                comment: this.props.currentOrder.comment,
                ravenSerialNumbers: Array(this.props.currentOrder.quantity - this.props.currentOrder.ravenCount).fill(undefined),
            });
        }
    }

    resizeRavenSerialNumbers = (newLength, defaultValue) => {
        while(newLength > this.state.ravenSerialNumbers.length){
            this.state.ravenSerialNumbers.push(defaultValue);
        }
        // only need the one length var here
        this.state.ravenSerialNumbers.length = newLength;
    }

    onOrderNoChange = (val) => {
        this.setState({orderno: val});
    }

    onCountChange = (val) => {
        var parsedCount = parseInt(val);

        if(isNaN(parsedCount)) {
                this.setState({count: 0});
                this.resizeRavenSerialNumbers(1, null);
        }

        if (!isNaN(parsedCount)) {
                if (this.props.currentOrder) {
                    if (this.state.ravenCount > parsedCount) {
                        parsedCount = this.state.ravenCount
                }
            }

            if (parsedCount > this.state.countMax) {
                parsedCount = this.state.countMax
            }


            // TODO: HERE SOMEWHERE??
            this.setState({count: parsedCount});
            if (this.props.currentOrder) {

                var remaining = parsedCount - this.props.currentOrder.ravenCount;

                if (remaining){
                    if (typeof(remaining) === "number") {
                        if (remaining >= 0) {
                            this.resizeRavenSerialNumbers(parsedCount - this.props.currentOrder.ravenCount, null);
                        }
                    }
                }
            }
            return;
        }

        if (this.props.currentOrder) {
            if (this.props.currentOrder.ravenCount > 0) {
                this.setState({count: this.props.currentOrder.ravenCount});
            } else {
                this.setState({count: 0});
                this.resizeRavenSerialNumbers(1, null);
            }
        }
    }

    onCommentChange = (val) => {
        this.setState({comment: val});
    }

    onSerialNumbersChange = (index, val) => {
        var serialNumbers = this.state.ravenSerialNumbers;
        serialNumbers[index] = val;
        this.setState({ravenSerialNumbers: serialNumbers});
    }

    inputSelectAll = (idx, event) => {
        const target = event.target;


        setTimeout(() => {
            if (!target) return;
            this.setState({currentlySelectedField: idx})
            target.select();
        }, 100); // helps ensure a single click select all

    }

    onAddOrEditOrder = () => {

        console.log("Sending",this.state);

        if (this.props.currentOrder){
            // edit
            var result = this.props.onSuccess(
                this.state.orderno, this.state.count, this.state.comment, this.state.ravenSerialNumbers, this.state.shippedDate
            )

            // if edit returns 0, one of the entered serial #'s was empty
            // we return early here to avoid trigerring the onClose() and
            // state reset at the end of this fn, so that the user does not risk
            // losing all entered serial #'s
            if (result == 0) {
                var emptyFieldMsg = `Warning: One or more submitted serial numbers were empty.`
                this.setState({modalNotificationIsOpen: true, modalNotificationText: emptyFieldMsg})
                return
            }
        } else{
            // add
            this.props.onSuccess(
                this.state.orderno, this.state.count, this.state.comment 
            );
        }

        // scroll back to top after edit clicked so notification is visible
        this.props.onClose();
        this.setState({ orderno: null , count: 0, comment: null})
    }

    renderDatePicker = () => {
        return <div className="col-12">
                    <div className="row">
                        <div className="col-3">
                        Shipped Date:
                        </div>
                        <div className="col-9">
                            <SingleDatePicker
                                date={this.state.shippedDate}
                                onDateChange={ date => this.setState({ shippedDate: date })}
                                focused={this.state.datePickerFocused}
                                onFocusChange={({ focused }) => this.setState({ datePickerFocused: focused })}
                                id="shippedDate"
                                showDefaultInputIcon={false}
                                numberOfMonths={1}
                                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())} // disable past dates
                                noBorder={true}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
    }


    serialFieldOnComplete = (idx, value) => {
        if (idx == this.state.currentlySelectedField) {
            if (typeof(value) === "string") {
                if (value.startsWith('1RV') || value.startsWith ('2RV') || value.startsWith('1ShiftRShiftV') || value.startsWith('2ShiftRShiftV')) {
                        var nextfield = document.querySelector(
                            `input[name=ravenserialnumberinput-${idx+1}]`
                            );

                        if (nextfield !== null) {
                            //jumping to next field
                            nextfield.focus();
                    }

                }
            }
        }
    }

    renderRavenSerialNumberInputs = () => {
        if (this.state.ravenSerialNumbers) {
            if (this.state.ravenSerialNumbers.length > 0) {
                var serialNumberInputs = this.state.ravenSerialNumbers.map((val, idx) =>(
                    <div className="col-12">
                        <div className="row">
                            <div className="col-3" align="left">
                                Serial #{idx+1}:
                            </div>
                            <div className="col-9" align="right">
                                <RavenSerialNumberInput
                                    idx={idx}
                                    type="text"
                                    setOnChange={(e) => { this.onSerialNumbersChange(idx, e.target.value) }}
                                    setOnFocus={(e) => this.inputSelectAll(idx, e)}
                                    setOnComplete={(e) => {this.serialFieldOnComplete(idx, e)}}
                                    ravenSerialNumbers={this.state.ravenSerialNumbers}
                                />
                            </div>
                        </div>
                    </div>
                ));
            }

            return <div className='col-12'><div className="row"> Raven Serial Numbers </div> {serialNumberInputs}</div>
        }
        // if null or 0
        return null;
    }

    renderProcessOrderFields = () => {
        if (this.props.currentOrder) {
            // only show fields if the # Ravens on order > # Ravens already processed on order
            if (this.state.count && (this.props.currentOrder.ravenCount == 0)) {
                if (this.state.ravenSerialNumbers) {
                    // not sure if this is necessary since it should increment
                    if (this.state.ravenSerialNumbers.length > 0) {
                        // only allow a certain number of fields for perf
                        if (this.state.ravenSerialNumbers.length > this.state.maxSerialNumbersLength) {
                            this.state.ravenSerialNumbers.length = this.state.maxSerialNumbersLength
                        }
                        return ([
                            this.renderDatePicker(),
                            this.renderRavenSerialNumberInputs()
                        ]
                        )
                    }
                }
            }
        }

        return null;
    }

    render() {

        var orderIdReadOnly = false;

        var title = "Add Order";
        var buttonText = "Add";
        if(this.props.currentOrder)
        {
            title = "Edit Order";
            buttonText = "Edit";
            orderIdReadOnly = true;
        }

        if(this.props.data && this.props.data.account && this.props.data.account.email)
        {
            title += " for ";
            title += this.props.data.account.email;
        }

        return (
            <Dialog
                size="small"
                label={title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >

                { this.state.modalNotificationIsOpen ? <div className="row" id="modal-notification"> {this.state.modalNotificationText} </div> : null }
                <div className="row">
                    <div className="col-3">
                        Order Id #:
                    </div>
                    <div className="col-9">
                        <input name="orderno"
                            readOnly={orderIdReadOnly}
                            type="text"
                            onChange={(e) => { this.onOrderNoChange(e.target.value) }}
                            value={this.state.orderno}
                        />
                    </div>
                    <div className="col-3">
                        Num Ravens:
                    </div>
                    <div className="col-9">
                        <input name="count"
                            type="text"
                            onChange={(e) => { this.onCountChange(e.target.value) }}
                            value={this.state.count}
                        />
                    </div>
                    <div className="col-3">
                        Comment:
                    </div>
                    <div className="col-9">
                        <input name="comment"
                            type="text"
                            onChange={(e) => { this.onCommentChange(e.target.value) }}
                            value={this.state.comment}
                        />
                    </div>

                    {this.renderProcessOrderFields()}

                    <div className="col-12">
                        <div align="right">
                            <button onClick={this.props.onClose}>Cancel</button>
                            <button onClick={this.onAddOrEditOrder}>{buttonText}</button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}
